<template>
  <div class="alert-viewer" v-if="alert">
    <h1 class="title" v-if="alert._id">Edit Alert</h1>
    <h1 class="title" v-else>Create Alert</h1>

    <card title="Stats">
      <TextProperty title="Last modified" :value="$date(alert.updatedAt).format('LLL')" />
      <TextProperty
        title="Last triggered"
        :value="alert.lastTriggered ? $date(alert.lastTriggered).fromNow() : 'never'"
      />
    </card>

    <div class="alert-holder">
      <div class="alert-item" v-bind:class="{ active: activeStep === 1 }">
        <h4 class="alert-item-title" @click="activateStep(1)">Step 1. General properties</h4>
        <div class="alert-item-body">
          <grid>
            <grid-col size="6">
              <!--Name-->
              <div class="property">
                <div class="title">Name</div>
                <input type="text" placeholder="Name" class="input-text" v-model="alert.name" />
              </div>
            </grid-col>
            <grid-col size="6">
              <!--Description-->
              <div class="property">
                <div class="title">Description</div>
                <textarea
                  class="input-text"
                  v-model="alert.description"
                  placeholder="Describe what this alert does..."
                />
              </div>
            </grid-col>
          </grid>
          <grid>
            <grid-col size="6">
              <!--Interval-->
              <div class="property">
                <div class="title">Interval</div>
                <select name="interval" class="input-select" v-model="alert.interval">
                  <option>Select interval</option>
                  <option v-for="seconds in durations" :key="seconds" :value="seconds">
                    {{ duration(seconds * 1000) }}
                  </option>
                </select>
              </div>
            </grid-col>
            <grid-col size="6">
              <!--Available-->
              <div class="property">
                <div class="title">Available</div>
                <label>
                  <input type="checkbox" v-model="alert.available" /> Yes, is available to alert
                </label>
              </div>
            </grid-col>
          </grid>
        </div>
      </div>
      <div class="alert-item" v-bind:class="{ active: activeStep === 2 }">
        <h4 class="alert-item-title" @click="activateStep(2)">Step 2. Which devices to match</h4>
        <div class="alert-item-body">
          <grid>
            <grid-col size="6">
              <!--Device type-->
              <div class="property">
                <div class="title">Device type</div>
                <div v-if="!alert._id">
                  <select
                    name="devicetype"
                    class="input-select"
                    v-model="alert.devicetype"
                    @change="getDeviceCount"
                  >
                    <option>Select devicetype</option>
                    <option v-for="value in Object.keys(devicetypes)" :key="value" :value="value">
                      {{ devicetypes[value] }}
                    </option>
                  </select>
                </div>
                <div v-else>
                  {{ devicetypes[alert.devicetype] }}
                </div>
              </div>
            </grid-col>
            <grid-col size="6">
              <!--Tags-->
              <div class="property">
                <div class="title">Tags to include</div>
                <vue-tags-input
                  class="tags-input"
                  v-model="tags.include.text"
                  :tags="tags.include.value"
                  :autocomplete-min-length="0"
                  :autocomplete-items="tags.include.autoComplete"
                  :add-only-from-autocomplete="true"
                  @tags-changed="
                    (newTags) => {
                      tags.include.value = newTags;
                      getDeviceCount();
                    }
                  "
                />
              </div>
            </grid-col>
          </grid>
          <grid>
            <grid-col size="6">
              <div class="property">
                <div class="title">Matching devices:</div>
                <div>
                  {{ deviceMatch }}
                </div>
              </div>
            </grid-col>
            <grid-col size="6">
              <!--Tags-->
              <div class="property">
                <div class="title">Tags to exclude</div>
                <vue-tags-input
                  class="tags-input"
                  v-model="tags.exclude.text"
                  :tags="tags.exclude.value"
                  :autocomplete-min-length="0"
                  :autocomplete-items="tags.exclude.autoComplete"
                  :add-only-from-autocomplete="true"
                  @tags-changed="
                    (newTags) => {
                      tags.exclude.value = newTags;
                      getDeviceCount();
                    }
                  "
                />
              </div>
            </grid-col>
          </grid>
        </div>
      </div>

      <div class="alert-item" v-bind:class="{ active: activeStep === 3 }">
        <h4 class="alert-item-title" @click="activateStep(3)">Step 3. When does the alert fire</h4>
        <div class="alert-item-body">
          <div class="property" v-if="alert.devicetype === 'magicinfo'">
            <div class="title">Only match power status</div>
            <label class="switch" v-bind:class="{ open: alert.onlyPower }">
              <input type="checkbox" v-model="alert.onlyPower" />
              <span class="slider round" />
            </label>
            <span v-if="alert.onlyPower"
              >Devices that are turned OFF when their timer requires them to be ON trigger this
              alert</span
            >
          </div>

          <div v-if="!alert.onlyPower || alert.devicetype !== 'magicinfo'">
            <h3>Create custom rules</h3>
            <QueryBuilder :deviceType="alert.devicetype" />
          </div>
        </div>
      </div>

      <div class="alert-item" v-bind:class="{ active: activeStep === 4 }">
        <h4 class="alert-item-title" @click="activateStep(4)">Step 4. Who to notify</h4>
        <div class="alert-item-body">
          <grid>
            <grid-col size="6">
              <!--Recipient groups-->
              <div class="property">
                <div class="title">Recipient groups</div>
                <vue-tags-input
                  class="tags-input"
                  placeholder="Select recipient groups"
                  v-model="recipients.text"
                  :tags="recipients.value"
                  :autocomplete-min-length="0"
                  :autocomplete-items="recipients.autoComplete"
                  @tags-changed="(newGroups) => (recipients.value = newGroups)"
                />
              </div>
            </grid-col>
            <grid-col size="6">
              <!--Threshold-->
              <div class="property">
                <div class="title">Threshold</div>
                <input
                  type="text"
                  placeholder="Threshold"
                  class="input-text"
                  v-model="alert.threshold"
                />
              </div>
            </grid-col>
          </grid>
        </div>
      </div>
    </div>
    <card>
      <div class="buttons-wrapper">
        <base-button
          iconType="PLUS"
          title="Save changes"
          isSmall
          :isDisabled="!alert.onlyPower || !getParsedQuery"
          v-on:click="onSave"
        />
        <base-button
          type="THEMED"
          iconType="CANCEL"
          :isSmall="true"
          v-on:click="cancel()"
          title="Cancel"
        />
      </div>
    </card>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Utils from '@/utils';
import VueTagsInput from '@johmun/vue-tags-input';
import BaseComponent from '../Base.vue';
import QueryBuilder from './QueryBuilder/QueryBuilder.vue';
import TextProperty from '../Property/TextProperty.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'singlealert',
  extends: BaseComponent,
  components: {
    Grid,
    GridCol,
    QueryBuilder,
    VueTagsInput,
    TextProperty,
    Card,
    BaseButton,
  },
  props: ['alertId'],
  watch: {
    alertId(newVal) {
      this.getAlert(newVal);
    },
  },
  computed: {
    ...mapGetters('alertQuery', ['getParsedQuery']),
  },
  data() {
    return {
      ajaxCompleted: false,
      alert: null,
      adevicetypes: ['All', 'Linux', 'Windows', 'MagicInfo'],
      devicetypes: {
        all: 'All',
        linux: 'Linux',
        windows: 'Windows',
        magicinfo: 'MagicInfo',
      },
      durations: [300, 600, 900, 1800, 3600, 7200, 6 * 3600, 24 * 3600],

      recipients: {
        autoComplete: [],
        value: [],
        text: '',
      },

      tags: {
        include: {
          autoComplete: [],
          value: [],
          text: '',
        },

        exclude: {
          autoComplete: [],
          value: [],
          text: '',
        },
      },

      filter: [],
      activeStep: 1,
      deviceMatch: 0,
    };
  },
  methods: {
    duration(ms) {
      return Utils.duration(ms);
    },
    userCan(...args) {
      return Utils.userCan(...args);
    },
    async getAlert(id) {
      if (id === -1) {
        const response = await Utils.fetch('/api/v1/alerts/helper/empty', {}, this).then((res) =>
          res.json(),
        );
        if (response.success) {
          this.ajaxCompleted = true;
          this.alert = response.alert;
        }
      } else {
        const response = await Utils.fetch(`/api/v1/alerts/${id}`, {}, this).then((res) =>
          res.json(),
        );
        if (response.success) {
          this.ajaxCompleted = true;
          this.alert = response.alert;

          if (this.alert.tags) {
            this.tags.include.value = this.alert.tags.include.map((e) => ({ text: e })) || [];
            this.tags.exclude.value = this.alert.tags.exclude.map((e) => ({ text: e })) || [];
          }

          if (this.alert.recipients) {
            this.recipients.value = this.alert.recipients.map((e) => ({ text: e.name })) || [];
          }
          await this.getDeviceCount();
        }
      }
    },
    async onSave() {
      this.alert.rules = this.getParsedQuery;
      this.alert.tags = {
        include: this.tags.include.value,
        exclude: this.tags.exclude.value,
      };
      this.alert.recipients = this.recipients.value;
      if (this.alert && this.alert._id) {
        await this.handleOnEdit();
      } else {
        await this.handleOnSave();
      }
    },
    async handleOnEdit() {
      const response = await Utils.fetch(
        `/api/v1/alerts/${this.alert._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            alert: this.alert,
          }),
        },
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.$noty.success('Successfully edited alert');
        this.$emit('saved');
        this.$store.dispatch('alertQuery/resetValues');

        await this.$router.push('/alerts/manager/');
      } else {
        this.$noty.warning(response.message);
      }
    },
    async handleOnSave() {
      const response = await Utils.fetch(
        '/api/v1/alerts',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            alert: this.alert,
          }),
        },
        this,
      ).then((res) => res.json());

      if (response.success) {
        this.$noty.success('Successfully created alert');
        this.$emit('saved');
        await this.$router.push('/alerts/manager');
      } else {
        this.$noty.warning(response.message);
      }
    },
    async getDeviceCount() {
      const response = await Utils.fetch(
        '/api/v1/alerts/helper/matches',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            os: this.alert.devicetype,
            tags: {
              include: this.tags.include.value,
              exclude: this.tags.exclude.value,
            },
          }),
        },
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.deviceMatch = response.count;
      }
    },
    async getTags() {
      const response = await Utils.fetch('/api/v1/tags', {}, this).then((res) => res.json());
      if (response.length > 0) {
        this.tags.include.autoComplete = response.map((e) => ({ text: e }));
        this.tags.exclude.autoComplete = response.map((e) => ({ text: e }));
      }
    },
    async getRecipientGroups() {
      const response = await Utils.fetch('/api/v1/alerts/helper/groups', {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.recipients.autoComplete = response.alertGroups.map((e) => ({ text: e.name })) || [];
      }
    },
    cancel() {
      this.$router.push(`/alerts/manager/${this.alertId}`);
    },
    activateStep(step) {
      if (step === 3 && !this.alert.devicetype) {
        this.$noty.warning('You must select a devicetype first');
      } else if (step === 4) {
        this.$store.dispatch('alertQuery/parseQuery');
        if (!this.getParsedQuery) {
          this.$noty.error('Not all query fields are valid.');
        } else {
          this.activeStep = step;
        }
      } else {
        this.activeStep = step;
      }
    },
  },
  mounted() {
    this.getTags();
    this.getRecipientGroups();
    this.getAlert(this.alertId);
  },
};
</script>
<style lang="scss" scoped>
.alert-holder {
  border: 1px solid #23282c;
  margin-bottom: 15px;
  border-radius: 3px;

  .alert-item-title {
    background: var(--accent-color);
    height: 34px;
    line-height: 34px;
    margin: 0;
    padding: 0 15px;
    font-size: 14px;
    cursor: pointer;
    border-bottom: 1px solid #23282c;

    &:hover {
      opacity: 0.8;
    }
  }

  .alert-item-body {
    padding: 15px 15px;
    display: none;
  }

  .alert-item {
    &.active .alert-item-body {
      display: block;
    }
  }
}
.buttons-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>
